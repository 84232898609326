import { extractLinkInfo } from '/machinery/extractLinkInfo'
import { useElementSize } from '@kaliber/use-element-size'
import { Icon } from '/features/buildingBlocks/Icon'
import { LanguageSwitch } from '/features/buildingBlocks/menu/LanguageSwitch'
import { useSpring, useSpringValue, animated } from 'react-spring'
import styles from './Submenu.css'

import iconChevronDown from '/images/icons/chevron-down.raw.svg'

export function SubmenuLanguage({ label, expand, onExpand }) {
  return (
    <Base {... { label, expand, onExpand }}>
      <div className={styles.componentLanguage}><LanguageSwitch /></div>
    </Base>
  )
}

export function Submenu({ label, items, expand, onExpand, id }) {
  return (
    <Base {... { label, expand, onExpand, id }}>
      <ul className={cx(styles.component, expand && styles.expand)}>
        {items && items.map((item) => {
          const { href, label, target } = extractLinkInfo(item)
          return (
            <li className={styles.listItem} key={item._key}>
              <SubmenuLink {...{ label, href, target }} />
            </li>
          )
        })}
      </ul>
    </Base>
  )
}

function Base({ label, expand, onExpand, id = undefined, children }) {
  const { ref: elementRef, size } = useElementSize()
  const heightAnimation = useSpring({
    height: expand ? size.height : 0,
    opacity: expand ? 1 : 0,
    config: { tension: 300, friction: expand ? 10 : 50 }
  })

  const innerAnimation = useSpring({
    opacity: expand ? 1 : 0,
    config: { tension: 200, friction: 20 }
  })

  return (
    <div className={styles.componentBase}>
      <button
        onClick={() => onExpand(!expand)}
        className={styles.button}
        aria-controls={id}
        aria-expanded={expand}
      >
        {label}
        <Icon icon={iconChevronDown} layoutClassName={cx(styles.icon, expand && styles.rotate)} />
      </button>
      <animated.div className={styles.wrapper} style={heightAnimation}>
        <animated.div className={styles.inner} style={innerAnimation} ref={elementRef}>
          {children}
        </animated.div>
      </animated.div>
    </div>
  )
}

function SubmenuLink({ layoutClassName = undefined, href, label, target }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined

  return (
    <a className={cx(styles.componentLink, layoutClassName)} {...{ href, target, rel }} data-x='link-in-menu'>
      <span className={styles.linkInner}>{label}</span>
    </a>
  )
}
