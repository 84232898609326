import { useTranslate } from '/machinery/I18n'
import { Logo } from '/features/buildingBlocks/menu/Logo'
import { Menu } from '/features/buildingBlocks/menu/Menu'
import { ButtonBlack, ButtonWhite } from '/features/buildingBlocks/Button'
import styles from './MenuMobile.css'

export function MenuMobile({ active, onActiveChange, items, ticketLink }) {
  const [subMenuItem, setSubMenuItem] = React.useState(null)
  const { __ } = useTranslate()
  const hasAlotOfItems = items && items.length >= 5

  React.useEffect(
    () => { if (!active) setSubMenuItem(null) },
    [active]
  )

  return (
    <div className={cx(styles.component, hasAlotOfItems && styles.hasAlotOfItems)}>
      {active ? (
        <ButtonBlack
          onClick={onActiveChange}
          dataX='close-menu'
          layoutClassName={styles.button}
          ariaLabel={__`close-menu`}
          ariaExpanded={active}
        >
          {__`mobile-menu-close-label`}
        </ButtonBlack>
      ) : (
        <ButtonWhite
          onClick={onActiveChange}
          dataX='expand-menu'
          layoutClassName={styles.button}
          ariaLabel={__`open-menu`}
          ariaExpanded={active}
        >
          {__`menu-label`}
        </ButtonWhite>
      )}

      <div className={cx(styles.wrapper, active && styles.active)}>
        <div className={cx(styles.logoWrapper, active && styles.active)}>
          <Logo />
        </div>

        <Menu {...{ active, items, subMenuItem, ticketLink }} onActiveItemChange={handleActiveItemChange} layoutClassName={styles.menuMobile} />
      </div>
    </div>
  )

  function handleActiveItemChange(item) {
    setSubMenuItem(subMenuItem === item ? null : item)
  }
}
