import { useTranslations } from '/machinery/Translations'
import { useTranslate } from '/machinery/I18n'

import styles from './LanguageSwitch.css'

export function LanguageSwitch() {
  const translations = useTranslations()
  const { __ } = useTranslate()

  return (
    <ul className={styles.component}>
      {translations.map(({ language, href, current }, i) => (
        <li key={i}>
          <a
            className={styles.link}
            hrefLang={language}
            aria-current={current}
            aria-label={current
              ? `${__`language-current`}: ${__`language-translation-label-${language}`}`
              : __`language-translation-label-${language}`
            }
            {...{ href }}
          >
            <span className={cx(styles.input, current && styles.checked)} id={language} />
            {__`language-translation-label-${language}`.toLowerCase()}
          </a>
        </li>
      ))}
    </ul>
  )
}
