import { MenuMobile } from '/features/buildingBlocks/menu/MenuMobile'
import { MenuDesktop } from '/features/buildingBlocks/menu/MenuDesktop'
import { Logo } from '/features/buildingBlocks/menu/Logo'
import { useSpring, animated } from 'react-spring'
import styles from './SiteHeader.css'

export function SiteHeader({ menu, layoutClassName }) {
  const [menuActive, setMenuActive] = React.useState(true)
  const [menuMobileActive, setMenuMobileActive] = React.useState(false)
  const [logoVisible, setLogoVisible] = React.useState(true)
  const [firstPlay, setFirstPlay] = React.useState(true)
  const [isHovered, setIsHovered] = React.useState(false)
  const topRef = React.useRef(0)
  const logoScale = isHovered ? 1.3 : 1
  const logoAnimation = useSpring({
    scale: logoVisible ? logoScale : 0,
    config: {
      tension: 200,
      friction: (
        firstPlay ? 50 :
        logoVisible ? 10 :
        20
      ),
    },
    onRest() { setFirstPlay(false) }
  })
  React.useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }

      function handleScroll() {
        setLogoVisible(window.scrollY < 50)

        if (window.scrollY === 0) {
          topRef.current = 0
          setMenuActive(true)
        } else {
          const scrollDistance = Math.abs(topRef.current - window.scrollY)
          setMenuActive(active => active && scrollDistance < 50)
        }
      }
    },
    []
  )

  return (
    <header className={cx(styles.component, layoutClassName)}>
      <div className={styles.layout}>
        <animated.div
          style={logoAnimation}
          className={styles.logoWrapper}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Logo />
        </animated.div>

        {menu && <MenuDesktop
          active={menuActive}
          items={menu.items}
          ticketLink={menu.ticketLink}
          onActiveChange={handleMenuActiveChange}
        />}
      </div>

      {menu && <MenuMobile
        onActiveChange={handleMenuMobileActiveChange}
        active={menuMobileActive}
        items={menu.items}
        ticketLink={menu.ticketLink}
      />}
    </header>
  )

  function handleMenuActiveChange() {
    topRef.current = window.scrollY
    setMenuActive(x => !x)
  }

  function handleMenuMobileActiveChange() {
    setMenuMobileActive(x => !x)
  }
}
