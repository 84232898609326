import { useTranslate } from '/machinery/I18n'
import { Menu } from '/features/buildingBlocks/menu/Menu'
import { useElementSize } from '@kaliber/use-element-size'
import { ButtonBlack, ButtonWhite } from '/features/buildingBlocks/Button'
import { useSpring, animated } from 'react-spring'

import styles from './MenuDesktop.css'

export function MenuDesktop({ items, ticketLink, onActiveChange, active }) {
  const [subMenuItem, setSubMenuItem] = React.useState(null)
  const [firstPlay, setFirstPlay] = React.useState(true)
  const { size: { width }, ref: elementRef } = useElementSize()
  const { __ } = useTranslate()

  const hasMultipleItems = items && items.length >= 5

  const widthAnimation = useSpring({
    width: active ? width : 130,
    config: { tension: 250, friction: firstPlay ? 25 : 50 },
    onRest() {
      setFirstPlay(false)
    }
  })

  return (
    <nav className={cx(styles.component, hasMultipleItems && styles.hasMultipleItems)}>
      <animated.div
        className={styles.wrapper}
        style={widthAnimation}
      >
        <div ref={elementRef} className={styles.inner}>
          {active
            ? <Menu {...{ active, items, subMenuItem }} onActiveItemChange={handleActiveItemChange} />
            : (
              <ButtonWhite
                onClick={onActiveChange}
                dataX='expand-menu'
                ariaLabel={__`open-menu`}
                ariaExpanded={active}
                layoutClassName={styles.button}
              >
                {__`menu-label`}
              </ButtonWhite>
            )}
        </div>
      </animated.div>

      {ticketLink && (
        <ButtonBlack
          href={ticketLink.href}
          dataX='link-in-menu-to-tickets'
          layoutClassName={styles.ticketButton}
          target={ticketLink.openInNewTab ? '_blank' : undefined}
        >
          {ticketLink.label}
        </ButtonBlack>
      )}
    </nav>
  )

  function handleActiveItemChange(item) {
    setSubMenuItem(subMenuItem === item ? null : item)
  }
}
