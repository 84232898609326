import { LinkMenu } from '/features/buildingBlocks/Link'
import { useTranslate } from '/machinery/I18n'
import { extractLinkInfo } from '/machinery/extractLinkInfo'
import { Submenu, SubmenuLanguage } from '/features/buildingBlocks/menu/Submenu'
import { useSpring, animated } from 'react-spring'

import styles from './Menu.css'

export function Menu({ active, items, subMenuItem, onActiveItemChange, ticketLink = undefined, layoutClassName = undefined }) {
  const languageSubmenu = 'LANGUAGE_SUBMENU'
  const { __ } = useTranslate()

  const linkAnimation = useSpring({
    from: { opacity: 0, y: 20 },
    to: { opacity: 1, y: 0 },
    delay: 300
  })

  return (
    <ul className={cx(styles.component, active && styles.active, layoutClassName)}>
      {items?.map((x) => {
        const { href, label, target } = extractLinkInfo(x)

        return (
          <animated.li style={linkAnimation} key={x._key}>
            {['internalLink', 'externalLink'].includes(x._type)
              ? <LinkMenu dataX='link-in-menu' {...{ href, target }}>
                {label}
              </LinkMenu>
              : <Submenu id={x._key} expand={x === subMenuItem} onExpand={expand => onActiveItemChange(expand ? x : null)} items={x.items} label={x.label} />
            }
          </animated.li>
        )
      })}

      <animated.li style={linkAnimation} className={styles.languageSwitch}>
        <SubmenuLanguage
          label={__`language`}
          onExpand={() => onActiveItemChange(languageSubmenu)}
          expand={subMenuItem === languageSubmenu}
        />
      </animated.li>

      {ticketLink && (
        <animated.li style={linkAnimation} className={styles.ticketLink}>
          <LinkMenu
            href={ticketLink.href}
            target={ticketLink.openInNewTab ? '_blank' : undefined}
            layoutClassName={styles.ticketButton}
            dataX='link-in-menu-to-tickets'
          >
            {ticketLink.label}
          </LinkMenu>
        </animated.li>
      )}
    </ul>
  )
}
