import { Icon } from '/features/buildingBlocks/Icon'
import { routeMap } from '/routeMap'
import { useTranslate, useLanguage } from '/machinery/I18n'

import logo from '/images/logo-nijntje-museum-background.raw.svg'
import styles from './Logo.css'

export function Logo({ layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <a
      className={cx(styles.component, layoutClassName)}
      href={routeMap.app.home({ language })}
      data-x='link-to-home'
      aria-label={__`main-menu-link-to-home-label`}
    >
      <Icon icon={logo} />
      <p className={styles.copyright}>&copy; Mercis bv</p>
    </a>
  )
}
